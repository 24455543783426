import React from "react"
import { teamSizes } from "@config"
import { MobileFilterCloseButton } from "../DateSelector"

import * as styles from "./index.module.less"

const TeamSizeSelector = ({
  teamSize = "",
  setTeamSize = () => null,
  dismissibleForMobiles = false,
  className = "",
  inline = false,
}) => {
  return (
    <div
      className={`${styles.ts_wrapper} ${className} ${
        inline ? styles.inline : ""
      }`}
      onClick={e => e.stopPropagation()}
    >
      {typeof dismissibleForMobiles === "function" && (
        <MobileFilterCloseButton onClick={dismissibleForMobiles} />
      )}
      <h4>Team size</h4>
      <TeamSizeUl teamSize={teamSize} setTeamSize={setTeamSize} />
      {/*<ul>*/}
      {/*  {teamSizes.map((ts, idx) => {*/}
      {/*    return (*/}
      {/*      <li key={idx}>*/}
      {/*        <button*/}
      {/*          className={`theme-ts-button ${*/}
      {/*            teamSize === ts ? "theme-ts-active" : ""*/}
      {/*          }`}*/}
      {/*          onClick={() => setTeamSize(ts)}*/}
      {/*        >*/}
      {/*          <span>{ts}</span>*/}
      {/*        </button>*/}
      {/*      </li>*/}
      {/*    )*/}
      {/*  })}*/}
      {/*</ul>*/}
    </div>
  )
}

export default TeamSizeSelector

export const TeamSizeUl = ({ teamSize, setTeamSize = () => null }) => {
  return (
    <ul className={styles.team_size_ul}>
      {teamSizes.map((ts, idx) => {
        return (
          <li key={idx}>
            <button
              className={`theme-ts-button ${
                String(ts) === String(teamSize) ? "theme-ts-active" : ""
              }`}
              onClick={() => setTeamSize(ts)}
            >
              <span>{ts}</span>
            </button>
          </li>
        )
      })}
    </ul>
  )
}
