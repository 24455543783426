import React from "react"
import * as styles from "./index.module.less"
import PlusIcon from "@images/icons/plus.svg"
import MinusIcon from "@images/icons/minus.svg"

const Index = ({
  count = 0,
  setCount = () => null,
  className = "",
  min = 0,
  max = 10,
}) => {
  return (
    <div
      className={`${styles.counter} ${className}`}
      onClick={e => {
        e.stopPropagation()
        e.preventDefault()
      }}
    >
      <button
        disabled={count <= min}
        onClick={() => {
          setCount(count > min ? count - 1 : min)
        }}
      >
        <img src={MinusIcon} alt="Decrease seat count" />
      </button>
      <span>{count}</span>
      <button
        disabled={count >= max}
        onClick={() => {
          setCount(count < max ? count + 1 : max)
        }}
      >
        <img src={PlusIcon} alt="Increase seat count" />
      </button>
    </div>
  )
}

export default Index
