import isUUID from "validator/es/lib/isUUID"
import { format, parse } from "date-fns"

export const teamSizes = process.env.GATSBY_TEAM_SIZES
  ? process.env.GATSBY_TEAM_SIZES.split(",")
  : "1,2,3,4,5,6,7,8,9,10".split(",")

export const cities = process.env.GATSBY_CITIES
  ? process.env.GATSBY_CITIES.split(",")
  : "Toronto".split(",")

export const hasHourlyOnDemand = !!process.env.GATSBY_HOURLY_ON_DEMAND

export const hasDayPasses = !!process.env.GATSBY_DAY_PASSES

export const isSingleSpace =
  process.env.GATSBY_SPACE_UUID && isUUID(process.env.GATSBY_SPACE_UUID)

export const dayPassesType = {
  name: "Day Passes",
  slug: "day-passes",
  search_endpoint: "search/day-passes",
  reservation_endpoint: "reserve/day-passes",
}

export const onDemandType = {
  name: "On-demand Offices",
  slug: "on-demand-offices",
  search_endpoint: "search/on-demand-spaces",
  reservation_endpoint: "per-day-reservation",
}

export const resTypes = !!process.env.GATSBY_DAY_PASSES
  ? [dayPassesType, onDemandType]
  : [onDemandType]

export const onDemandTypes = ["Daily", "Hourly"]

export const today = parse(
  format(new Date(), "yyyy-MM-dd"),
  "yyyy-MM-dd",
  new Date()
)
