import React from "react"
import PropTypes from "prop-types"
import "./styles.less"

// This will be absolutely positioned center center to the parent element.

const ProgressDots = ({ active = false }) => (
  <span className={`progress-dots ${active ? "active" : ""}`}>
    <span />
    <span />
    <span />
  </span>
)
export default ProgressDots

ProgressDots.propTypes = {
  active: PropTypes.bool,
}
