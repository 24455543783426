import qs from "query-string"
import { navigate } from "gatsby"

const updateQs = (id = null, value = null) => {
  if (!id || !value) return
  const params =
    typeof window !== "undefined" ? qs.parse(window.location.search) : {}

  const ids = id.split(",")
  const values = value.split(",")

  for (let i = 0; i < ids.length; i++) {
    const currentId = ids[i]
    const currentValue = values[i]

    if (
      [
        "team_size",
        "space_type",
        "term_length",
        "move_in_date",
        "location",
        "move_out_date",
        "res_type",
        "start_date",
        "start_time",
        "end_date",
        "end_time",
        "on_demand_type",
      ].includes(currentId)
    ) {
      params[currentId] = currentValue
    }
  }
  //
  // if (
  //   [
  //     "current_staff",
  //     "space_type",
  //     "term_length",
  //     "move_in_date",
  //     "move_out_date",
  //   ].includes(id)
  // ) {
  //   params[id] = value
  // }
  // if (id === "budget") {
  //   params.budget_min = value.min
  //   params.budget_max = value.max
  // }

  navigate(`${window.location.pathname}?${qs.stringify(params)}`)
}

export default updateQs
