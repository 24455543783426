import React from "react"
import * as styles from "./index.module.less"

import { onDemandTypes } from "@config"

const OnDemandTypeSelector = ({ type = "", setType = () => null }) => {
  return (
    <ul className={styles.on_demand_type_ul}>
      {onDemandTypes.map(t => (
        <li key={t}>
          <button
            onClick={() => setType(t)}
            className={type === t ? styles.on_demand_active_type : ""}
          >
            {t}
          </button>
        </li>
      ))}
    </ul>
  )
}

export default OnDemandTypeSelector
