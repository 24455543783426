import imageOptimizer from "../services/imageOptimizer"

const SM = 640
const MD = 1200
const LG = 1920

const useOptimizedImages = (image, sm, md, lg, xlg) => {
  const windowSize = typeof window !== "undefined" ? window.innerWidth : 1200

  if (windowSize > LG) {
    return imageOptimizer(image, xlg || sm)
  }
  if (windowSize > MD) {
    return imageOptimizer(image, lg || sm)
  }
  if (windowSize > SM) {
    return imageOptimizer(image, md || sm)
  }
  return imageOptimizer(image, sm)
}

export default useOptimizedImages
