import React from "react"
import PropTypes from "prop-types"
import GoogleMapReact from "google-map-react"
import mapStyles from "./mapStyles"
import "./index.less"

const K_CIRCLE_SIZE = 200
const K_STICK_SIZE = 220

function mapOptionsCreator(map) {
  return {
    zoomControlOptions: {
      position: map.ControlPosition.LEFT_TOP,
    },
    styles: mapStyles,
  }
}

const ResultsMap = ({ children, ...props }) => {
  const distanceToMouse = (markerPos, mousePos, markerProps) => {
    const x = markerPos.x
    // because of marker non symmetric,
    // we transform it central point to measure distance from marker circle center
    // you can change distance function to any other distance measure
    const y = markerPos.y - K_STICK_SIZE - K_CIRCLE_SIZE / 2

    // and i want that hover probability on markers with text === 'A' be greater than others
    // so i tweak distance function (for example it's more likely to me that user click on 'A' marker)
    // another way is to decrease distance for 'A' marker
    // this is really visible on small zoom values or if there are a lot of markers on the map
    const distanceKoef = markerProps.text !== "A" ? 1.5 : 1

    // it's just a simple example, you can tweak distance function as you wish
    return (
      distanceKoef *
      Math.sqrt(
        (x - mousePos.x) * (x - mousePos.x) +
          (y - mousePos.y) * (y - mousePos.y)
      )
    )
  }

  return (
    <div className="results-map">
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_MAPS_API_KEY }}
        hoverDistance={30}
        distanceToMouse={distanceToMouse}
        options={mapOptionsCreator}
        {...props}
      >
        {children}
      </GoogleMapReact>
    </div>
  )
}
const Marker = ({ text, selected, url, className = "" }) => {
  if (url && !text) {
    return (
      <a
        href={url}
        className={`${
          selected ? "map-marker active" : "map-marker "
        } ${className}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <svg
          width="35"
          height="35"
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="25" cy="25" r="25" fill="black" />
          <path
            className={"theme-map-marker"}
            d="M24.9999 9.40552C18.6029 9.40552 13.3999 14.6085 13.3999 21.0055C13.3999 23.2742 13.9594 24.9016 15.1374 26.643L24.3374 40.243C24.4107 40.3513 24.5094 40.4399 24.6249 40.5012C24.7404 40.5625 24.8692 40.5945 24.9999 40.5945C25.1306 40.5945 25.2594 40.5625 25.3749 40.5012C25.4904 40.4399 25.5891 40.3513 25.6624 40.243L34.8624 26.643C36.0404 24.9016 36.5999 23.2742 36.5999 21.0055C36.5999 14.6085 31.3969 9.40552 24.9999 9.40552ZM24.9999 11.0055C30.5322 11.0055 34.9999 15.4732 34.9999 21.0055C34.9999 23.0363 34.6007 24.1836 33.5374 25.7555L24.9999 38.3805L16.4624 25.7555C15.3991 24.1837 14.9999 23.0363 14.9999 21.0055C14.9999 15.4732 19.4676 11.0055 24.9999 11.0055ZM24.9999 15.0055C21.9166 15.0055 19.3999 17.5222 19.3999 20.6055C19.3999 23.6888 21.9166 26.2055 24.9999 26.2055C28.0832 26.2055 30.5999 23.6888 30.5999 20.6055C30.5999 17.5222 28.0832 15.0055 24.9999 15.0055ZM24.9999 16.6055C27.2185 16.6055 28.9999 18.3869 28.9999 20.6055C28.9999 22.8241 27.2185 24.6055 24.9999 24.6055C22.7813 24.6055 20.9999 22.8241 20.9999 20.6055C20.9999 18.3869 22.7813 16.6055 24.9999 16.6055Z"
            fill="#ffffff"
          />
        </svg>
      </a>
    )
  }
  return (
    <p
      className={`${
        selected ? "map-marker active" : "map-marker "
      } ${className}`}
    >
      {text && (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      )}
    </p>
  )
}

export { ResultsMap, Marker }

ResultsMap.propTypes = {
  children: PropTypes.node,
}

Marker.propTypes = {
  text: PropTypes.string,
  url: PropTypes.string,
  className: PropTypes.string,
  selected: PropTypes.bool,
}
