import React, { useState, useEffect } from "react"
import { format, isBefore, isValid, parse } from "date-fns"
import getTimeSlots from "../../../utils/getTimeSlots"
import { useSelector } from "react-redux"

const LONG_FORMAT = "yyyy-MM-dd HH:mm XXX"

const TimePicker = ({
  date = new Date(),
  value = new Date(),
  setValue = () => null,
  isEndTime = false,
  startTime = new Date(),
}) => {
  const closingTime = useSelector(state => state.profile.closingTime)
  const openingTime = useSelector(state => state.profile.openingTime)

  const [timeSlots, setTimeSlots] = useState([])

  useEffect(() => {
    if (!date) return

    const ts = getTimeSlots(
      openingTime,
      closingTime,
      format(date, "yyyy-MM-dd")
    )

    if (ts.length > 0) {
      if (isEndTime) {
        ts.shift()
      } else {
        ts.pop()
      }
    }

    setTimeSlots(ts)
  }, [date, openingTime, closingTime])

  const minTime = new Date()

  const formatted_date = format(date, "yyyy-MM-dd")
  const tz = format(date, "XXX")

  const startDateString = `${formatted_date} 00:00 ${tz}`

  date = parse(startDateString, LONG_FORMAT, new Date())

  return (
    <select
      className={"form-control"}
      value={value}
      onChange={e => setValue(new Date(e.target.value))}
    >
      <option value="" disabled>
        {isEndTime ? "End time" : "Start time"}
      </option>
      {timeSlots.map(time => {
        const validStartTime = startTime && isValid(startTime)
        return (
          <option
            value={time}
            key={time}
            disabled={
              (isEndTime && !validStartTime) ||
              (isEndTime && validStartTime && !isBefore(startTime, time))
            }
          >
            {format(time, "hh:mm a")}
          </option>
        )
      })}
      {/*{[0, 1, 2, 3, 4, 5, 6, 7, 8].map(number => {*/}
      {/*  if (!date) return null*/}

      {/*  const currentTime =*/}
      {/*    number === 0*/}
      {/*      ? addMinutes(date, 9 * 60)*/}
      {/*      : addMinutes(date, 60 * (9 + number))*/}

      {/*  if (!isEndTime && number === 8) return null*/}
      {/*  if (isEndTime && number === 0) return null*/}

      {/*  if (*/}
      {/*    startTime &&*/}
      {/*    date &&*/}
      {/*    isEndTime &&*/}
      {/*    isBefore(currentTime, addMinutes(startTime, 60))*/}
      {/*  )*/}
      {/*    return null*/}

      {/*  if (isEndTime && !startTime) return null*/}

      {/*  return (*/}
      {/*    <option*/}
      {/*      value={currentTime}*/}
      {/*      key={number}*/}
      {/*      disabled={isBefore(currentTime, minTime)}*/}
      {/*    >*/}
      {/*      {format(currentTime, "h:mm a")}*/}
      {/*      /!*{isEndTime*!/*/}
      {/*      /!*  ? ` (${differenceInMinutes(currentTime, startTime) / 60} hours)`*!/*/}
      {/*      /!*  : ""}*!/*/}
      {/*    </option>*/}
      {/*  )*/}
      {/*})}*/}
    </select>
  )
}

export default TimePicker
