import axios from "axios"
import { parse } from "date-fns"

const provinces = [
  { city: "Toronto", province: "ON" },
  { city: "Calgary", province: "AB" },
  { city: "Kitchener", province: "ON" },
]

const fetchHolidays = async () => {
  const res = await axios
    .get("https://canada-holidays.ca/api/v1/holidays")
    .catch(error => {
      console.log(error)
      // send slack message
    })

  // const holidays = res.data.holidays.map(holiday =>
  //   parse(holiday.observedDate, "yyyy-MM-dd", new Date())
  // )

  let finalHolidays = {}

  provinces.map(location => {
    const filteredHolidays =
      res && res.data && res.data.holidays
        ? res.data.holidays.filter(holiday => {
            return holiday.provinces && holiday.provinces.length > 0
              ? holiday.provinces.find(
                  province => province.id === location.province
                )
              : false
          })
        : []
    finalHolidays[location.city] = filteredHolidays.map(holiday => {
      return parse(holiday.observedDate, "yyyy-MM-dd", new Date())
    })
    return null
  })

  return finalHolidays
}

export default fetchHolidays
