import React from "react"
import * as styles from "./index.module.less"

import Timepicker from "./Timepicker"

const HourlyTimePicker = ({
  alwaysBottom = false,
  startDate,
  startTime,
  endTime,
  setStartTime = () => null,
  setEndTime = () => null,
}) => {
  return (
    <div
      className={`${styles.right} ${
        alwaysBottom ? "" : styles.right_on_desktops
      }`}
    >
      <p className={styles.time_heading}>
        Start time&nbsp;&nbsp;&#10230;&nbsp;&nbsp;&nbsp;End time
      </p>
      <ul className={styles.time_slots}>
        <li>
          <Timepicker
            date={startDate}
            startTime={startTime}
            value={startTime}
            setValue={setStartTime}
          />
        </li>
        <li>
          <Timepicker
            date={startDate}
            startTime={startTime}
            value={endTime}
            setValue={setEndTime}
            isEndTime
          />
        </li>
      </ul>
    </div>
  )
}

export default HourlyTimePicker
