import { toZonedTime } from "date-fns-tz"
import { addBusinessDays, format, isAfter, parse } from "date-fns"
import isWorkhaus from "./isWorkhaus"

const getNextAvailableDate = (timezone = "America/Toronto") => {
  const clientTimeNow = toZonedTime(new Date(), timezone)

  const clientToday = parse(
    format(clientTimeNow, "yyyy-MM-dd"),
    "yyyy-MM-dd",
    new Date(),
  )

  const endTime = isWorkhaus ? "10:00" : "16:00"

  const clientTimeEnd = parse(
    `${format(clientTimeNow, "yyyy-MM-dd")} ${endTime}`,
    "yyyy-MM-dd H:m",
    new Date(),
  )

  const days = isAfter(clientTimeEnd, clientTimeNow) ? 0 : 1

  return addBusinessDays(clientToday, days)
}

export default getNextAvailableDate
