import { differenceInBusinessDays, isWithinInterval } from "date-fns"

const countWorkingDays = (start, end, holidays = []) => {
  if (!start || !end) {
    return 1
  }
  const weekdays = differenceInBusinessDays(end, start) + 1
  if (holidays.length === 0) {
    // return the date gap
    return weekdays
  }
  const activeHolidays = holidays.filter(holiday => {
    return isWithinInterval(holiday, {
      start,
      end,
    })
  }).length

  const final = Number(weekdays - activeHolidays)

  return final > 0 ? final : 1
}

export default countWorkingDays
