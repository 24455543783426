import React, { useState } from "react"
import * as styles from "./index.module.less"
import Datepicker from "react-datepicker"
import OnDemandTypeSelector from "../OnDemandTypeSelector"
import HourlyTimePicker from "../HourlyTimePicker"
import { differenceInHours, format, parse } from "date-fns"
import getNextAvailableDate from "../../../utils/getNextAvailableDate"
import { hasHourlyOnDemand, onDemandTypes } from "@config"
import { setValues } from "../../../stores/ProfileStore/actions"
import { navigate } from "gatsby"
import updateQs from "../../../Layouts/Results/SearchParams/updateQs"
import qs from "query-string"
import countWorkingDays from "@utils/countWorkingDays"
import CloseIcon from "@images/icons/close.svg"
import { useDispatch, useSelector } from "react-redux"
import maxDateForCalendar from "../../../utils/maxDateForCalendar"

const DateSelector = ({
  setDateRange = () => null,
  dateRange = {},
  closeFilter = () => null,
  dismissible = null,
  dismissibleForMobiles = false,
  city = null,
}) => {
  // include holidays here
  const holidays = useSelector(state => state.results.holidays)

  const dispatch = useDispatch()

  const minDateGap = getNextAvailableDate(city)

  const params =
    typeof window !== "undefined" ? qs.parse(window.location.search) : {}

  const [type, setType] = useState(params.on_demand_type || onDemandTypes[0])

  const [startDate, setStartDate] = useState(
    params.start_date
      ? parse(params.start_date, "yyyy-MM-dd", new Date())
      : null
  )
  const [endDate, setEndDate] = useState(
    params.end_date ? parse(params.end_date, "yyyy-MM-dd", new Date()) : null
  )

  const [startTime, setStartTime] = useState(
    params.start_time
      ? parse(
          `${params.start_date} ${params.start_time.toLowerCase()}`,
          "yyyy-MM-dd hh:mm a",
          new Date()
        )
      : ""
  )
  const [endTime, setEndTime] = useState(
    params.end_time
      ? parse(
          `${params.start_date} ${params.end_time.toLowerCase()}`,
          "yyyy-MM-dd hh:mm a",
          new Date()
        )
      : ""
  )

  const updateOnDemandPicker = dates => {
    if (dates.length === 2) {
      const [start, end] = dates
      if (start && !end) {
        setStartDate(start)
        setEndDate(null)
      } else if (start && end) {
        setStartDate(start)
        setEndDate(end)
        closeAndUpdateDaily(start, end)

        closeFilter()
      }
    } else {
      setStartDate(dates)
    }
  }

  const closeAndUpdateHourly = endTime => {
    if (!endTime || !startTime || !startDate) {
      return
    }
    updateQs(
      "start_date,end_date,start_time,end_time,on_demand_type",
      `${format(startDate, "yyyy-MM-dd")},${format(
        startDate,
        "yyyy-MM-dd"
      )},${format(startTime, "hh:mm a")},${format(endTime, "hh:mm a")},Hourly`
    )
    dispatch(
      setValues({
        isPerDayOnDemand: false,
        onDemandDaysHoursCount: differenceInHours(endTime, startTime),
      })
    )
    closeFilter()
  }

  const closeAndUpdateDaily = (startDate, endDate) => {
    if (!endDate || !startDate) {
      return
    }
    updateQs(
      "start_date,end_date,on_demand_type",
      `${format(startDate, "yyyy-MM-dd")},${format(
        endDate,
        "yyyy-MM-dd"
      )},Daily`
    )
    dispatch(
      setValues({
        isPerDayOnDemand: true,
        onDemandDaysHoursCount: countWorkingDays(startDate, endDate, []),
      })
    )
    closeFilter()
  }

  const clearValues = () => {
    delete params.start_date
    delete params.end_date
    delete params.start_time
    delete params.end_time
    params.on_demand_type = "Daily"
    navigate(`/?${qs.stringify(params)}`)
    closeFilter()
  }

  return (
    <div
      className={`${styles.dp_wrapper} ${
        params.start_date ? styles.dp_active : ""
      }`}
      onClick={e => e.stopPropagation()}
    >
      {typeof dismissibleForMobiles === "function" && (
        <MobileFilterCloseButton onClick={dismissibleForMobiles} />
      )}

      <div>
        {hasHourlyOnDemand && (
          <OnDemandTypeSelector type={type} setType={setType} />
        )}

        <Datepicker
          inline
          selectsRange={type === onDemandTypes[0]}
          maxDate={maxDateForCalendar(process.env.GATSBY_CLIENT_UUID || "")}
          minDate={minDateGap}
          selected={startDate}
          onChange={updateOnDemandPicker}
          startDate={startDate}
          endDate={type === "Daily" ? endDate : null}
          excludeDates={holidays[city] || []}
        />
      </div>
      {type === onDemandTypes[1] && startDate && (
        <HourlyTimePicker
          startDate={startDate}
          startTime={startTime}
          endTime={endTime}
          setStartTime={setStartTime}
          setEndTime={value => {
            setEndTime(value)
            closeAndUpdateHourly(value)
          }}
        />
      )}
      {params.start_date && <button onClick={clearValues}>Clear</button>}
    </div>
  )
}

export const MobileFilterCloseButton = ({ onClick = () => null }) => (
  <div className={styles.close_button_wrapper}>
    <button onClick={onClick}>
      <img src={CloseIcon} alt="Close" />
    </button>
  </div>
)

export default DateSelector
