import { addDays, addWeeks, differenceInDays, endOfWeek } from "date-fns"

const workhausId =
  process.env.NODE_ENV === "development"
    ? "50b1ed74-a502-4833-b4c3-9453d3998c9d"
    : "2f33867f-cd37-4778-9daa-a07836ebd907"

const maxDateForCalendar = (id = "") => {
  let daysToAdd = 60
  if (id === workhausId) {
    const endDate = addWeeks(new Date(), 2)
    const endWeek = endOfWeek(endDate)
    daysToAdd = differenceInDays(endWeek, new Date())
  }
  return addDays(new Date(), daysToAdd)
}

export default maxDateForCalendar
