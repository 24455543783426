import wrapInApiUrl from "../utils/wrapInApiUrl"
import axios from "axios"

const request = async (slug, data = {}, overrideAccessToken = null) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${process.env.GATSBY_WS_API_TOKEN}`,
  }

  return axios({
    method: "post",
    url: wrapInApiUrl(slug),
    headers,
    data,
  }).then(r => {
    return r.data || {}
  })
}

export default request
