import { addMinutes, format, isBefore, isSameMinute, parse } from "date-fns"

const getTimeSlots = (
  startTime = "8:00 AM",
  endTime = "5:00 PM",
  date = format(new Date(), "yyyy-MM-dd"),
  gap = 60
) => {
  let timeSlots = []
  const openingTimeString = `${date} ${startTime}`
  const closingTimeString = `${date} ${endTime}`
  let ot = parse(openingTimeString, "yyyy-MM-dd hh:mm a", new Date())
  const ct = parse(closingTimeString, "yyyy-MM-dd hh:mm a", new Date())

  while (isBefore(ot, ct) || isSameMinute(ot, ct)) {
    // do this
    timeSlots.push(ot)
    ot = addMinutes(ot, gap)
  }
  return timeSlots
}

export default getTimeSlots
