import axios from "axios"

const notifyError = (error = "", location = null) => {
  if (process.env.NODE_ENV === "development") {
    return
  }
  const message = location && location.id ? generateMessage(location) : error
  if (!message) return true
  const url =
    "https://hooks.slack.com/services/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"

  axios
    .post(url, JSON.stringify({ text: message }), {
      withCredentials: false,
      transformRequest: [
        (data, headers) => {
          delete headers.post["Content-Type"]
          return data
        },
      ],
    })
    .then(res => console.log("failed operation logged"))

  return true
}

export default notifyError

const generateMessage = (location = {}) => {
  const {
    deleted_at,
    stripe_connected_account_id,
    on_demand_emails,
    contact_email,
    contact_name,
    is_live,
    is_test,
  } = location
  let missing = []

  let message = `Tried to view an invalid location at <${
    typeof window !== "undefined" ? window.location.href : ""
  }|${process.env.GATSBY_CLIENT_NAME}>`

  if (!is_live) {
    message += `\nLocation is not active.`
    return message
  }
  if (is_test) {
    message += `\nThis is a test space.`
    return message
  }
  if (deleted_at) {
    message += `\nDeleted location.`
    return message
  }

  message += `\nMissing Details: `
  if (!stripe_connected_account_id) {
    missing.push("Stripe Account ID")
  }
  if (!on_demand_emails) {
    missing.push("On-demand Emails")
  }
  if (!contact_email) {
    missing.push("Contact Email")
  }
  if (!contact_name) {
    missing.push("Contact Name")
  }
  message += missing.join(", ")
  return message
}
